import React from "react";

function About() {
  return (
    <>
      <h1>Mentions légales</h1>
      <p>
        En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l’économie numérique, il est précisé aux utilisateurs du
        présent site web l’identité des différents intervenants dans le cadre de
        sa réalisation et de son suivi.
      </p>
      <p>
        Propriétaire : Association Mouvement Artrope – Association, loi 1901 – 7
        rue Jean Jaurès – 28300 Lèves
      </p>
      <p>
        Créateur : Responsable publication : Colette Leroux (présidente) –
        +33(0)9 84 13 16 40 – asso.mouvement.artrope[at]wanadoo.fr
        <br />
        Le responsable de la publication est une personne physique et une
        personne morale.
      </p>
      <p>
        Webmaster : Colette Leroux (présidente) – +33(0)9 84 13 16 40 –
        asso.mouvement.artrope[at]wanadoo.fr
      </p>
      <p>
        Hébergeur : Vercel - 340 S Lemon Ave #4133 - Walnut (HQ), CA - United States

      </p>
      <p>Développement : SCOP ARL Incaya - 14 rue Ambroise Bully 77100 MEAUX</p>
      <h3>Propriété intellectuelle et contrefaçons</h3>
      <p>
        L’A.ssociation M.ouvement A.rtrope est propriétaire des droits de
        propriété intellectuelle ou détient les droits d’usage sur tous les
        éléments accessibles sur le site, notamment les textes, images,
        graphismes, logo, icônes, sons, vidéos, logiciels.
      </p>
      <p>
        Toute reproduction, représentation, modification, publication,
        adaptation de tout ou partie des éléments du site, quel que soit le
        moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
        préalable de l’A.ssociation M.ouvement A.rtrope.
      </p>
      <p>
        Toute exploitation non autorisée du site ou de l’un quelconque des
        éléments qu’il contient sera considérée comme constitutive d’une
        contrefaçon et poursuivie conformément aux dispositions des articles
        L.335-2 et suivants du Code de Propriété Intellectuelle.
      </p>
      <h3>Gestion des données personnelles</h3>
      <p>
        En France, les données personnelles sont notamment protégées par la loi
        n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article
        L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
      </p>
      <p>
        Aucune donnée personnelle n'est collectée et aucun cookie n'est déposé à
        l’occasion de l’utilisation du présent site.
      </p>
    </>
  );
}

export default About;
