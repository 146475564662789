import React, { Component } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import styled from "styled-components";
import "./ClusterMap.css";

import { customIcon } from "./MarkerIcons";

class ClusterMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 48.896461,
      lng: -0.19582,
      zoom: 8,
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.markers !== prevProps.markers &&
  //     null !== this.props.markers
  //   ) {
  //     this.setState({ center: this.props.initialCenter });
  //   }
  // }

  handleMoveend = (e) => {
    const map = e.target;
    const mapBounds = map.getBounds();
    const visiblePoints = this.props.markers.filter((p) =>
      mapBounds.contains(p.position)
    );
    this.props.onChangeVisiblePoints(visiblePoints);
  };

  showProject = (event) => this.props.selectEvent(event);

  render() {
    const position = [this.state.lat, this.state.lng];
    const { events } = this.props;
    return (
      <StyledMap
        id="vudici-map"
        center={position}
        zoom={this.state.zoom}
        maxZoom={18}
        // onMoveend={this.handleMoveend}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup>
          {events &&
            events.map((p, i) => (
              <Marker
                key={i}
                position={[p.coordinates.x, p.coordinates.y]}
                icon={
                  p.location_type && p.location_type.color
                    ? customIcon(p.location_type.color)
                    : customIcon("#000000")
                }
              >
                <Popup>
                  <strong>{p.name}</strong>
                  <br />
                  {p.location_name}
                  <br />
                  <p style={{textAlign: "right", fontSize: "0.9em"}}>
                    <button onClick={() => this.showProject(p)}>
                      Voir le détail du projet
                    </button>
                  </p>
                </Popup>
              </Marker>
            ))}
        </MarkerClusterGroup>
      </StyledMap>
    );
  }
}

export default ClusterMap;

const StyledMap = styled(Map)`
  align-self: center;
  width: 100%;
  min-height: 300px;
  flex-grow: 1;
`;
