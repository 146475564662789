import L from "leaflet";

export const customIcon = (color) => {
  const markerHtmlStyles = `
    background-color: ${color};
    width: 3rem;
    height: 3rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`
  
  const icon = L.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyles}" />`
  })
  
  return icon
  // return new L.Icon({
  //   iconUrl: "/markers/" + iconFile,
  //   iconRetinaUrl: "/markers/" + iconFile,
  //   iconAnchor: [5, 55],
  //   popupAnchor: [10, -44],
  //   iconSize: [25, 55],
  //   // shadowUrl: '../assets/marker-shadow.png',
  //   shadowSize: [68, 95],
  //   shadowAnchor: [20, 92],
  //   markerColor: "red",
  // });
};
