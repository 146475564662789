import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useMedia } from "react-media";
import ReactPlayer from "react-player/lazy";

import "./Event.css";

const GLOBAL_MEDIA_QUERIES = {
  small: "(max-width: 599px)",
  medium: "(min-width: 600px) and (max-width: 1199px)",
  large: "(min-width: 1200px)",
};

function Event(props) {
  // const event = useLocation().state.data;
  const { eventId } = useParams();
  const eventData = props.data ? props.data : null;
  const [event, setEvent] = useState(eventData);
  useEffect(() => {
    async function getEvent() {
      const res = await fetch("/api/events/" + eventId);
      const newEvent = await res.json();
      setEvent(newEvent);
    }
    if (!event) {
      getEvent();
    }
  }, []);
  useEffect(() => {
    setEvent(eventData);
  }, [eventData]);

  return (
    <>
      {null !== event && (
        <div className="event-block">
          <h2>{event.name}</h2>
          <p>à {event.location_name}</p>
          <div className="event-videos">
            {event.video_links &&
              event.video_links.map((vid, index) => (
                <React.Fragment key={index}>
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#000000"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4h-4z" />
                    </svg>
                    {vid.name}
                  </h3>
                  <div style={{ width: "100%" }}>
                    <ReactPlayer
                      url={vid.url}
                      controls={true}
                      light={true}
                      width="100%"
                    />
                  </div>
                </React.Fragment>
              ))}
          </div>
          <div className="event-sounds">
            {event.sound_links &&
              event.sound_links.map((sound, index) => (
                <React.Fragment key={index}>
                  <h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#000000"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M7 18h2V6H7v12zm4 4h2V2h-2v20zm-8-8h2v-4H3v4zm12 4h2V6h-2v12zm4-8v4h2v-4h-2z" />
                    </svg>
                    {sound.name}
                  </h3>
                  <div style={{ width: "100%" }}>
                    <ReactPlayer
                      url={sound.url}
                      controls={true}
                      light={true}
                      width="100%"
                    />
                  </div>
                </React.Fragment>
              ))}
          </div>
          <p dangerouslySetInnerHTML={{ __html: event.artistic_description }} />
          <div className="event-images">
            {event.image_links &&
              event.image_links.map((im, index) => (
                <img src={im.url} alt={im.name} key={index} />
              ))}
          </div>
          <p dangerouslySetInnerHTML={{ __html: event.didactic_description }} />
        </div>
      )}
    </>
  );
}

export default Event;
