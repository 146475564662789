import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactPlayer from "react-player/vimeo";
import ScrollableAnchor, { goToAnchor } from "react-scrollable-anchor";
import { isMobile } from "react-device-detect";

import { filterStyle } from "../styles/filters";

import ClusterMap from "./ClusterMap";

import Event from "./Event";

function Home() {
  const [projects, setProjects] = useState(null);
  useEffect(() => {
    async function getProjects() {
      const res = await fetch("/api/projects");
      const newProjects = await res.json();
      const newProjectsOptions = await newProjects.map((t) => ({
        value: t.id,
        label: t.name,
      }));
      setProjects(newProjectsOptions);
    }
    getProjects();
  }, []);
  const [selectedProjects, setSelectedProjects] = useState(null);

  const [locationTypes, setLocationTypes] = useState(null);
  useEffect(() => {
    async function getLocationTypes() {
      const res = await fetch("/api/location-types");
      const newLocationTypes = await res.json();
      const newLocationTypesOptions = await newLocationTypes.map((lt) => ({
        value: lt.id,
        label: lt.name,
        color: lt.color,
      }));
      setLocationTypes(newLocationTypesOptions);
    }
    getLocationTypes();
  }, []);
  const [selectedLocationTypes, setSelectedLocationTypes] = useState(null);

  const [tags, setTags] = useState(null);
  useEffect(() => {
    async function getTags() {
      const res = await fetch("/api/tags");
      const newTags = await res.json();
      const newTagsOptions = await newTags.map((t) => ({
        value: t.id,
        label: t.name,
      }));
      setTags(newTagsOptions);
    }
    getTags();
  }, []);
  const [selectedTags, setSelectedTags] = useState(null);

  const [events, setEvents] = useState(null);
  useEffect(() => {
    async function getEvents() {
      var fetchUrl = "/api/events?filter={";
      const selectedProjectParam =
        selectedProjects && selectedProjects.length > 0
          ? selectedProjects.map((p) => p.value).join(", ")
          : null;
      const selectedLocationTypesParam =
        selectedLocationTypes && selectedLocationTypes.length > 0
          ? selectedLocationTypes.map((lt) => lt.value).join(", ")
          : null;
      const selectedTagsParam =
        selectedTags && selectedTags.length > 0
          ? selectedTags.map((t) => t.value).join(", ")
          : null;
      var filters = [];
      if (selectedProjectParam) {
        filters.push('"project_id":[' + selectedProjectParam + "]");
      }
      if (selectedLocationTypesParam) {
        filters.push('"location_type_id":[' + selectedLocationTypesParam + "]");
      }
      if (selectedTagsParam) {
        filters.push('"tag_ids":[' + selectedTagsParam + "]");
      }
      fetchUrl += filters.join(", ");
      fetchUrl += "}";
      const res = await fetch(fetchUrl);
      const newEvents = await res.json();
      setEvents(newEvents);
    }
    getEvents();
  }, [selectedProjects, selectedLocationTypes, selectedTags]);
  const [event, setEvent] = useState(null);

  const goToEvent = () => {
    if (isMobile) {
      goToAnchor("event-zone");
    }
  };

  return (
    <>
      <div className="app-filters">
        <p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5l7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z" />
          </svg>{" "}
          Choisissez un projet ou un type de lieu, puis explorez la carte.
        </p>
        <Select
          options={projects}
          isSearchable={false}
          isMulti
          placeholder="Choisissez des projets"
          styles={filterStyle.project}
          onChange={(selections) => setSelectedProjects(selections)}
        />
        <Select
          options={locationTypes}
          isSearchable={false}
          isMulti
          placeholder="Choisissez un type de lieu"
          styles={filterStyle.locType}
          onChange={(selections) => setSelectedLocationTypes(selections)}
        />
        {/* <Select
          options={tags}
          isSearchable={false}
          isMulti
          placeholder="Choisissez des étiquettes"
          styles={filterStyle.tag}
          onChange={(selections) => setSelectedTags(selections)}
        /> */}
      </div>
      <div id="map-container">
        <ClusterMap
          events={events}
          selectEvent={(event) => {
            setEvent(event);
            goToEvent();
          }}
        />
      </div>
      <ScrollableAnchor id={"event-zone"}>
        <div/>
      </ScrollableAnchor>
      {event && <Event data={event} />}

      {/* <div className="event-details">
        {event && (
          <>
            <h2>
              <strong>
                <Link
                  to={{
                    pathname: `project/${event.id}`,
                    state: { data: event },
                  }}
                >
                  {event.name}
                </Link>
              </strong>
            </h2>
            <p>à {event.location_name}</p>
            <p
              dangerouslySetInnerHTML={{ __html: event.artistic_description }}
            />
            <div className="event-images">
              {event.image_links.map((im, index) => (
                <img src={im.url} alt={im.name} />
              ))}
            </div>
            {/* <img
              src="https://live.staticflickr.com/65535/49235339961_897c4675ac_m.jpg"
              width="192"
              height="240"
              alt="Dancer-04"
            /> */}
      {/* <p
              dangerouslySetInnerHTML={{ __html: event.didactic_description }}
            />
            <div style={{ width: "200px" }}>
              <ReactPlayer url="https://vimeo.com/199211673" />
            </div>
          </>
        )}
      </div> */}
    </>
  );
}

export default Home;
