import React from "react";
import mmnmVudici from "../img/mmnm-vudici.jpg";

function Mmnm() {
  return (
    <>
      <h1>Mélanie Marie / Nicolas Maurel</h1>
      <p>
        Au point de départ un duo, la rencontre de deux corps, de deux
        physicalités, de deux personnalités, de deux histoires qui, depuis 1999,
        ne cessent de réécrire et de conjuguer leur humanité. Car c’est bien
        l’humain qui est au centre de leurs démarches et de leurs préoccupations
        et réalisations d’artistes au quotidien entre créations, transmissions,
        médiations, représentations, collaborations… et s’effacent les
        frontières ! De ces multiples rencontres, en autant de lieux qu’ils leur
        sont donnés de traverser, affleurent alors, autant de danses partagées
        avec un public élargi à chaque nouvelles rencontres ou re-présentations.
      </p>
      <p>
        « Mélanie et Nicolas, deux artistes chorégraphiques hors-case, hors-sol,
        hors d’âge, hors réseaux parfois mais certainement pas hors service ! A
        force de premières fois et à essuyer des plâtres, ils excellent dans
        l’art d’ouvrir des portes qui n’existent pas toujours ou encore mais qui
        restent ouvertes après leur passage, tout occupés qu’ils sont à explorer
        déjà de nouveaux horizons. Aussi différents que complémentaires, ils
        sont toujours plus volontaires et engagés dans la création d’une danse
        actuelle, moderne ou contemporaine s’affranchissant de tout préjugé
        esthétique. Ils déploient leurs projets tentaculaires, portent et
        colportent partout où cela est possible, partagent tous azimuts sans se
        poser la question du qu’en dira-t-on. Toujours à danser d’un pied sur
        l’autre, à chercher leur équilibre dans le déséquilibre et à essayer de
        bouger aussi vite que le monde ne change. Le corps dansant est leur
        énergie renouvelable et inépuisable, il est le point de départ, le point
        d’arrivée. Tout ce qui est entre est matière à. »
      </p>
      <p>
        <strong>Vu d’Ici !</strong> est l’ultime forme du développement de leur
        projet artistique qui s’affine depuis la création de leur toute première
        pièce in situ en 2000/2002. Travaillant sur le territoire en dehors des
        sentiers battus depuis leur premiers essais chorégraphiques, ils
        s’intéresse depuis toujours à l’empreinte comme la trace laissé par leur
        travail.
      </p>
      <p style={{textAlign: "center", margin: "25px 0 0 0"}}>
        <img src={mmnmVudici} alt="Mélanie Marie / Nicolas Maurel" />
      </p>
    </>
  );
}

export default Mmnm;
