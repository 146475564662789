import chroma from 'chroma-js';

const project = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
    control: styles => ({ ...styles, 
      margin: '5px 0',
      backgroundColor: 'white'
    })
}
 
const locType = {
    control: styles => ({ ...styles, 
        margin: '5px 0',
        zIndex: 110,
        backgroundColor: 'white' 
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color ? data.color : '#000000';
        const ccolor = chroma(color);
        return {
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? color
            : isFocused
            ? ccolor.alpha(0.1).css()
            : null,
        color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
            : color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
            ...styles[':active'],
            backgroundColor:
            !isDisabled && (isSelected ? data.color : ccolor.alpha(0.3).css()),
        },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color ? data.color : '#000000');
        return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color ? data.color : '#000000',
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color ? data.color : '#000000',
        ':hover': {
        backgroundColor: data.color ? data.color : '#000000',
        color: 'white',
        },
    }),
};

const tag = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
    control: styles => ({ ...styles, 
        margin: '5px 0',
        backgroundColor: 'white'
    })
}

export const filterStyle = {
    project,
    locType,
    tag
};
