import React from "react";
import Menu from "react-burger-menu/lib/menus/slide";
import { Link } from "react-router-dom";
import { useState } from "react";

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "10px",
    top: "10px",
  },
  bmBurgerBars: {
    background: "#fcfcfc",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#4c4c4c",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#cfcfcf",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#ffffff",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
    textDecoration: "none"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  showSettings(event) {
    event.preventDefault();
  }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu
        styles={styles}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        <Link to="/" onClick={() => this.closeMenu()}>
          Moteur de recherche
        </Link>
        <Link to="/mmnm" onClick={() => this.closeMenu()}>
          M. Marie / N. Maurel
        </Link>
        <Link to="/about" onClick={() => this.closeMenu()}>
          Mentions légales
        </Link>
      </Menu>
    );
  }
}
