import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import BurgerMenu from "./components/BurgerMenu";
import Home from "./components/Home";
import About from "./components/About";
import Mmnm from "./components/Mmnm";
import EventDetail from "./components/EventDetail";
import NotFound from "./components/NotFound";

import logoLeader from "./img/logo-ue-leader.jpg";
import logoCalvados from "./img/logo-calvados-departement.jpg";

import "./App.css";

function App() {
  return (
    <Router>
      <main id="page-wrap">
        <div className="app-header">
          <BurgerMenu pageWrapId={"page-wrap"} />
          <h1>
            <Link to="/">Vu d'Ici !</Link>
          </h1>
        </div>
        <div id="page-content">
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/mmnm">
              <Mmnm />
            </Route>
            <Route path="/events/:eventId">
              <EventDetail />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
        <footer className="app-footer">
          <p className="footer-align-right">
            &copy; 2021 - A.ssociation M.ouvement A.rtrope -{" "}
            <Link to="/about">mentions légales</Link>
          </p>
          <p>
            Ce projet de cartographie est cofinancé par l’Union Européenne / GAL
            Sud Calvados et le département du Calvados{" "}
          </p>

          <p className="financeurs">
            <img className="img-financeur" src={logoLeader} alt="UE - Leader" />{" "}
            <img className="img-financeur" src={logoCalvados} alt="Département du Calvados" />
          </p>
        </footer>
      </main>
    </Router>
  );
}

export default App;
